import $ from 'jquery';
//import jQuery from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	console.log('document ready');

	function owlNewsCarouselSlider(){
		jQuery('.news-carousel-wrap').owlCarousel({
		    //center: true,
		    items: 3,
		    margin:10,
		    nav:false,
		    dots: true,
		    responsive:{
		        0:{
		            items:1
		        },
		        600:{
		            items:2
		        },
		        1000:{
		            items:3
		        }
		    }
		});
	}

	function owlSingleProjectsCarouselSlider(){
		jQuery('.projects-carousel-wrap').owlCarousel({
		    //center: true,
		    items: 3,
		    margin:10,
		    nav:false,
		    dots: true,
		    responsive:{
		        0:{
		            items:1
		        },
		        600:{
		            items:2
		        },
		        1000:{
		            items:3
		        }
		    }
		});
	}


	owlNewsCarouselSlider();
	owlSingleProjectsCarouselSlider();

	var el = document.querySelector('.projecten-grid');
	el.innerHTML = el.innerHTML.replace(/&nbsp;/g,'');

});
